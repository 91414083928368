import Header from './components/Header/Header';
import ScrollToHashElement from "@cascadia-code/scroll-to-hash-element";
import React from 'react';
import { toast, Toaster } from 'react-hot-toast';
import PatientTable from './PatientTable';
import DynamicText from './DynamicText';

function getFirstName(fullName) {
    // Check if fullName is a valid string and not empty
    if (typeof fullName !== 'string' || fullName.trim() === '') {
      return ''; // Return an empty string if the name is invalid
    }
    // Split the name by spaces and return the first part
    const nameParts = fullName.trim().split(/\s+/); // `\s+` handles multiple spaces
    return nameParts[0]; // Return the first word (first name)
}
function Dashboard({ user }) {
    const firstName = getFirstName(user.user_metadata.name);
    return(
        <div>
        <Toaster/>
        <ScrollToHashElement behavior="smooth" inline="center" block="center"/>
        <Header/>
        {user.user_metadata?.access_granted ? <PatientTable name={firstName} trialID={user.user_metadata?.clinical_trial_ID}/>:<div className="App  pt-40 flex-col  min-h-screen"><DynamicText text={`Hi ${firstName}. Access is in review. Our team will get back to you shortly...`}/> </div>}
        </div>
    );
}
export default Dashboard;
