import { useState, useEffect } from "react";
import { supabase } from './supabaseClient';
import DynamicText from './DynamicText'
import logo from './assets/transparent-logoo.png';

export default function PatientTable({ name, trialID}) {
  const [patients, setPatients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const userName = name;

  // Fetch patients data when the component mounts
  useEffect(() => {
    async function fetchPatients() {
      setLoading(true);
      setError(null);
      console.log("Fetching patients...");

      try {
        const { data, error } = await supabase
          .from('myPatients')
          .select('patientName, phoneNumber, patientEmail, dateOfMatch')
          .eq('trialID', trialID);

        if (error) {
          throw new Error(error.message);
        }

        // Log the received data
        console.log("Data received:", data);

        // Map the data to the correct structure
        const formattedPatients = data.map(patient => ({
          name: patient.patientName,
          phone: patient.phoneNumber,
          email: patient.patientEmail,
          matchDate: patient.dateOfMatch,
        }));

        setPatients(formattedPatients);
      } catch (err) {
        setError(err.message);
        console.error("Error fetching data:", err);
      } finally {
        setLoading(false);
      }
    }

    fetchPatients();
  }, [trialID]);
  if (loading) {
    return (<div className="logo-container">
        <img src={logo} alt="Logo" className="animated-logo" />
    </div>);
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="container mx-auto p-4">
      <div className="mb-6 text-center">
        <h1 className="text-2xl font-bold text-orange-600 mb-2"><DynamicText text={`Welcome back, ${userName}`}/></h1>
        <h2 className="text-xl font-semibold text-orange-500">Clinical Trial ID: {trialID}</h2>
      </div>
      <div className="overflow-x-auto">
        <table className="table-auto w-full border-collapse shadow-lg bg-white rounded-lg">
          <caption className="text-orange-600 font-semibold text-lg mb-4">
          {patients.length === 0 ? (
            <DynamicText text={`Across the US, doctors are being alerted as qualified patients are actively sought for trial ${trialID}. Hope is on the horizon...`} />
            ) : (
            <DynamicText text={`Here are the matched patients bringing us closer to groundbreaking outcomes.`} />
            )}
          </caption>

          <thead className="bg-orange-100">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-orange-800 uppercase tracking-wider">
                Patient Name
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-orange-800 uppercase tracking-wider">
                Phone Number
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-orange-800 uppercase tracking-wider">
                Email
              </th>

              <th className="px-6 py-3 text-left text-xs font-medium text-orange-800 uppercase tracking-wider">
                Date of Match
              </th>
            </tr>
          </thead>
          <tbody>
            {patients.map((patient, index) => (
              <tr
                key={index}
                className={index % 2 === 0 ? "bg-orange-50" : "bg-white"}
              >
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-orange-900">
                  {patient.name}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-orange-700">
                  {patient.phone}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-orange-700">
                  {patient.email}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-orange-700">
                  {patient.matchDate}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
