import React, { useEffect, useState } from 'react';

export default function DynamicText({text}) {

    const [displayedText, setDisplayedText] = useState("");
    const [index, setIndex] = useState(0);
    useEffect(() => {
        if (index < text.length) {
          const timeout = setTimeout(() => {
            setDisplayedText((prev) => prev + text[index]);
            setIndex(index + 1);
          }, 50); // Adjust the speed of typing here
          return () => clearTimeout(timeout);
        }
      }, [index, text]);
      return displayedText;
}